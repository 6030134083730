<!--
 * @Descripttion: 数据汇总
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-15 16:19:37
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-20 15:22:45
-->
<template>
    <div class="summarizationData">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">数据汇总</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <commonSelect
                    @changeDate="changeDate"
                    @clickTagDate="clickTagDate"
                ></commonSelect>
            </div>
        </div>
        <div class="case-wrap data-wrap"  v-loading="loading1">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">客户数据报表</div>
            </div>
            <div class="content-wrap">
                <div class="item-wrap">
                    <div class="item">
                        <span>{{infoData.count}}</span>客户总数
                    </div>
                    <div class="item">
                        <span>{{infoData.follow_count}}</span>跟进数
                    </div>
                    <div class="item">
                        <span>{{infoData.deal_count}}</span>成交数
                    </div>
                    <div class="item">
                        <span>{{infoData.return_visit_count}}</span>回访数
                    </div>
                </div>
                <div class="echart">
                    <div id="followGrades" style="width: 33%;height: 350px;"></div>
                    <div id="qualityGrades" style="width: 33%;height: 350px;"></div>
                    <div id="clientypeGrades" style="width: 33%;height: 350px;"></div>
                </div>
            </div>
            <div class="line-wrap">
                <div id="clienLineGrades" style="width:100%;height: 350px;"></div>
            </div>
        </div>
        <div class="case-wrap case-wrap">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">全景数据报表</div>
            </div>
            <div class="case" v-loading="loading2">
                <div id="sceneLineGrades" style="width: 100%;height: 350px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
import {followEcharts,qualityEcharts,clientypeEcharts} from "@/echarts/pieEchartData";
import {clientLineEcharts,sceneLineEcharts} from "@/echarts/lineEchartData";
export default {
    components:{
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'summarizationData',
            dateType: '',
            startDate: '',
            endDate: '',
            loading1: false,
            loading2: false,
            infoData: {},
        }
    },
    mounted () {
        this.getCustomerReportPieChart();
        this.getCustomerReport();
        this.getCustomerLineChart();
        this.getSceneLineChart();
    },
    methods: {
        // 获取全景折线图
        getSceneLineChart(){
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading2 = true;
            common.connect('/distributorpcv1/data_report/sceneDataReportLineChart',params,(res)=>{
                sceneLineEcharts('sceneLineGrades',res.data);
                this.loading2 = false;
            })
        },
        // 获取客户折线图
        getCustomerLineChart(){
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            common.connect('/distributorpcv1/data_report/customerDataReportLineChart',params,(res)=>{
                clientLineEcharts('clienLineGrades',res.data);
            })
        },
        // 获取客户总数
        getCustomerReport(){
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            common.connect('/distributorpcv1/data_report/customerReport',params,(res)=>{
                this.infoData = res.data;
            })
        },
        // 获取饼图数据
        getCustomerReportPieChart(){
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading1 = true;
            common.connect("/distributorpcv1/data_report/customerReportPieChart",params,(res)=>{
                followEcharts('followGrades',res.data.label_type);
                qualityEcharts('qualityGrades',res.data.quality);
                clientypeEcharts('clientypeGrades',res.data.customer_status);
                this.loading1 = false;
            });
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.startDate = startDate.slice(0,11)+'00:00:01';
            this.endDate = endDate.slice(0,11)+'23:59:59';
            this.getCustomerReportPieChart();
            this.getCustomerReport();
            this.getCustomerLineChart();
            this.getSceneLineChart();
        },
        clickTagDate(item){
            this.dateType = item.label;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getCustomerReportPieChart();
                this.getCustomerReport();
                this.getCustomerLineChart();
                this.getSceneLineChart();
            }
        },
    },
}
</script>

<style scoped lang='scss'>
.summarizationData{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 5px 0;
            .tag{
                width: 3px;
                height: 15px;
                background: #409EFF;
                border-radius: 10px;
            }
            .title{
                font-size: 16px;
                font-weight: bold;
                padding-left: 10px;
            }
        }
    }
    .data-wrap{
        .content-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: -10px;
            .item-wrap{
                border: 1px solid #ddd;
                border-radius: 6px;
                .item{
                    width: 260px;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 1px solid #eee;
                    span{
                        font-size: 26px;
                        font-weight: bold;
                        color: #000000;
                        margin-right: 10px;
                    }
                }
                .item:last-child{
                    border-bottom: none;
                }
            }
            .echart{
                width: calc(100% - 260px);
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        .line-wrap{
            margin-top: 30px;
        }
    }
}
</style>