/*
 * @Descripttion: 饼状图
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-01 15:37:02
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-20 08:44:41
 */
import * as echarts from 'echarts'

// 跟进阶段
function followEcharts(el,data) {
    var option = {
        title: {
            text: '跟进阶段',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '跟进阶段',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
                // formatter:  '{b} : {c} ({d}%)',
                // formatter:  '{c} ({d}%)',
                // position: 'inner',
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}
// 品质分类
function qualityEcharts(el,data) {
    var option = {
        title: {
            text: '品质分类',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '品质分类',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
                // formatter:  '{b} : {c} ({d}%)',
                // formatter:  '{c} ({d}%)',
                // position: 'inner',
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}
// 客户类型
function clientypeEcharts(el,data) {
    var option = {
        title: {
            text: '客户类型',
            left: 'center',
            top: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter:  '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            bottom: '0%',
        },
        color: ['#5f86e2','#61af80','#e6c69d','#fb7f36','#e15a56','#6eb5ef', '#c26cd5',  '#ffa500', '#40e0d0', '#1e90ff'],
        series: [{
            name: '客户类型',
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: true,
            itemStyle: {
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: true,
                // formatter:  '{b} : {c} ({d}%)',
                // formatter:  '{c} ({d}%)',
                // position: 'inner',
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: true,
            },
            data: data
        }]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}
  
export {
    followEcharts,
    qualityEcharts,
    clientypeEcharts
}