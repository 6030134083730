/*
 * @Descripttion: 折线图
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-01 16:22:42
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-20 15:20:36
 */
import * as echarts from 'echarts'

// 客户数据
function clientLineEcharts(el,data) {
    var option = {
        legend: {
          top: '5%',
          left: 'center'
        },
        grid: {
            x: 80,
            y: 60,
            x2: 20,
            y2: 20,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: data.categories
        },
        yAxis: {
          type: 'value'
        },
        color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
        series: [
          {
            name: data.series[0].name,
            data: data.series[0].data,
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top'
            },
          },
          {
            name: data.series[1].name,
            data: data.series[1].data,
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top'
            },
          },
          {
            name: data.series[2].name,
            data: data.series[2].data,
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top'
            },
          },
          {
            name: data.series[3].name,
            data: data.series[3].data,
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top'
            },
          }
        ]
      };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 全景数据
function sceneLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 80,
          y: 60,
          x2: 20,
          y2: 20,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        }
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}


export {
  clientLineEcharts,
  sceneLineEcharts
}
    